import React from 'react';
import css from './AlgoliaSearchServiceCard.module.css';
import { BrandingIconCard, Button, NamedLink, ResponsiveImage } from '../../components';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from 'react-intl';
import { SERVICE_LISTING_TYPE } from '../../util/types';

const AlgoliaSearchServiceCard = props => {
  const { hit, currentUser, roleName, addToFavorites } = props;

  const { title, price, images = [], publicData = {}, author = {} } = hit || {};
  const authorName = author?.displayName || '';

  const favoriteListings = currentUser?.attributes?.profile?.publicData?.favoriteListings || [];
  const selectedListing = { id: hit?.objectID, type: hit?.listingType };
  // Check if the selectedListing is already in favoriteListings
  const isFavorite = favoriteListings.some(listing => listing?.id === hit?.objectID);

  const id = hit?.objectID;
  const slug = createSlug(title);

  const isBooking = publicData?.listingType === SERVICE_LISTING_TYPE;

  return (
    <NamedLink name="ListingPage" params={{ id, slug }} className={css.servicesListBox}>
      <div className={css.serviceCard}>
        <div className={css.imageWrapper}>
                <ResponsiveImage
                 alt={title}
                  image={images[0]}
                  variants={"custom-image"}
                />
          <span
            className={isFavorite ? css.likeIconWrapper : css.wishListIcon}
            onClick={() => addToFavorites(selectedListing, favoriteListings)}
          >
            <BrandingIconCard type="heart" />
          </span>
        </div>
        <div className={css.detailWrapper}>
          <div className={css.titleDetails}>
            <h2 className={css.title}>{title}</h2>
            <div className={css.authorName}>{authorName ? authorName : null}</div>
            <div className={css.priceAmount}>
              {price?.currency} {price?.amount / 100}
            </div>
            <div className={css.reviewDetails}>
              <BrandingIconCard type="star" />
              <BrandingIconCard type="star" />
              <BrandingIconCard type="star" />
              <BrandingIconCard type="star" />
              <BrandingIconCard type="star_2" />
              <span className={css.reviewNumber}>20</span>
            </div>
          </div>
          <div className={css.buttonWrapper}>
            <Button className={css.button}><FormattedMessage id={isBooking ? 'SearchPage.book' : 'SearchPage.buy'} /></Button>
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

export default AlgoliaSearchServiceCard;
